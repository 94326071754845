@value DGS, AA, DSD from "../../components/Layout/Layout.module.scss";

// media query mixins
$mobile: 360px;
$tablet: 768px;
$desktop: 1024px;
$desktopWider: 1078px;

@mixin fromMobile {
  @media only screen and (min-width: $mobile) {
    @content;
  }
}

@mixin untilTablet {
  @media only screen and (max-width: $tablet) {
    @content;
  }
}

@mixin fromTablet {
  @media only screen and (min-width: $tablet) {
    @content;
  }
}

@mixin untilDesktop {
  @media only screen and (max-width: $desktop) {
    @content;
  }
}

@mixin fromDesktop {
  @media only screen and (min-width: $desktop) {
    @content;
  }
}

@mixin fromDesktopWider {
  // This is because of a modal issue - shouldn't be 95% and 1024px until this width, not 1024
  @media only screen and (min-width: $desktopWider) {
    @content;
  }
}

// colors
$grey: #e4e4e4;
$grey-disabled: #f4f4f4;
$grey-light: #f0f0f0;
$green: #00c853;
$blue: #1a9ee2;
$white: #fff;
$black: #00273b;
$valid: #d5f8e0;
$dark-blue: #00496c;
$valid-info: $dark-blue;
$red: #f04326;
$price: $green;
$price-button: $price;
$price-border: $price;
$default-price: #8ec9f9;
$lowest-price: #e3f2fe;
$tick: $green;
$listdot: $blue;
$right-col-width-original: 25%;
$right-col-width-tablet-original: 25%;
$left-col-width-original: calc(75% - 2rem);
$font-size-tiny: 1.1rem;
$font-size-smallest: 1.2rem;
$font-size-smaller: 1.4rem;
$font-size-small: 1.5rem;
$font-size-medium: 1.6rem;
$font-size-big: 1.7rem;
$font-size-big-price: 3.2rem;
$calendar-month-width-original: 47px;
$calendar-month-width-mobile-original: 47px;
$calendar-month-width-tablet-original: 47px;
$calendar-month-width-desktop-original: 47px;


$right-col-width: 34%;
$right-col-width-tablet: 34%;
$left-col-width: calc(66% - 2rem);
$scrollbarWidth: 30px;
$calendar-month-header-width: calc((100vw - 2rem) / 7);
$calendar-month-header-width-mobile: calc((100vw - 3rem) / 7);
$calendar-month-header-width-tablet: calc(
  ((((95vw - 4rem) * 0.66) - 2rem) / 7) - 1px
);
$calendar-month-header-width-desktop: calc(
  ((((1024px - 4rem) * 0.66) - 2rem) / 7) - 1px
);
$calendar-month-width: $calendar-month-header-width;
$calendar-month-width-mobile: $calendar-month-header-width-mobile;
$calendar-month-width-tablet: $calendar-month-header-width-tablet;
$calendar-month-width-desktop: $calendar-month-header-width-desktop;
$calendar-height: 425px;
$calendar-height-smallest: calc(((((100vw - 2rem) / 7) * 1.5) * 6) + 110px);
$calendar-height-mobile: calc(((((100vw - 3rem) / 7) * 1) * 6) + 110px);
$calendar-height-tablet: calc(
  ((((((95vw - 4rem) * 0.66) - 2rem) / 7) * 0.8) * 6) + 100px
);
$calendar-height-desktop: calc(
  ((((((1024px - 4rem) * 0.66) - 2rem) / 7) * 0.65) * 6) + 100px
);

// Seasons new colours
$season-eco: #f0f5fa;
$season-low: #e3f2fe;
$season-mid: #c6e4fc;
$season-holiday: #8ec9f9;
$season-reg: #1d93fe;
$season-high: #0a6ab9;

$ticket-range: $green;
$ticket-range-start: $green;
$ticket-range-end: $green;

// AA colors
$aa-cta: #018fe8;
$aa-cta-hover: lighten($aa-cta, 5%);
$aa-price: #000;
$aa-price-button: $aa-cta;
$aa-price-border: $grey;
$aa-tick: $green;
$aa-font-color: #525656;
$aa-heading-color: #3e3e3e;
$aa-listdot: $aa-font-color;
$aa-font: "muli", sans-serif;
$aa-heading-font: "muli", sans-serif;
$aa-general-margin: 0 0 3rem 0;
$aa-general-margin-small: 0 0 1.5rem 0;
$aa-pale-bg: #e6f3fd;
$aa-valid-info: #051146;

// DSD colors
$dsd-cta: #e43f8b;
$dsd-cta-hover: lighten($dsd-cta, 10%);
$dsd-price: #78cdd3;
$dsd-price-button: $dsd-cta;
$dsd-price-border: $grey;
$dsd-tick: $dsd-price;
$dsd-font-color: #656565;
$dsd-heading-color: #0e1d5f;
$dsd-listdot: $dsd-font-color;
$dsd-font: "camptonbook", arial, serif;
$dsd-heading-font: "camptonlight", arial, serif;
$dsd-general-margin: 0 0 3rem 0;
$dsd-general-margin-small: 0 0 1.5rem 0;
$dsd-pale-bg: #f8f8f8;
$dsd-valid-info: $dsd-heading-color;

// Make sure all sites can read rems the same
html {
  font-size: 62.5%;
  box-sizing: border-box;
  & * {
    box-sizing: border-box;
  }
}

// AA some general font styles to get started
.AA {
  font-size: $font-size-smaller;
  line-height: 1.5;
  font-family: $aa-font;
  color: $aa-font-color;
}

// DSD some general font styles to get started
.DSD {
  font-size: 1.5rem;
  line-height: 1.6;
  font-family: $dsd-font;
  color: $dsd-font-color;
}

// misc
@mixin container {
  padding: 2rem 0.5rem;
  border: 1px solid $grey;
  clear: both;
  position: relative;
  background-color: $white;

  @include fromMobile {
    padding: 2rem 1rem;
  }

  @include fromTablet {
    padding: 3rem 2rem;
  }

  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin tear {
  position: absolute;
  width: calc(100% - 2px);
  height: 40px;
  top: -20px;
  left: 1px;
  padding: 0 2rem;
  background: $white;

  span {
    border-top: 2px dashed $grey;
    display: block;
    width: 100%;
    margin-top: 20px;
    margin: 20px 1rem 1px;
    width: calc(100% - 2rem);
  }

  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 15px 0 15px 18px;
    border-color: transparent transparent transparent #777;
    left: -2px;
    top: 6px;

    @include untilTablet {
    }
  }

  &::after {
    left: auto;
    right: -2px;
    border-width: 15px 18px 15px 0;
    border-color: transparent #777 transparent transparent;
  }
}

@mixin visuallyHidden {
  position: absolute !important;
  overflow: hidden;
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
}

.visuallyHidden {
  @include visuallyHidden;
}

@mixin hideOnMobile {
  @include untilTablet {
    @include visuallyHidden;
  }
}

@mixin headingH1 {
  text-align: center;
  margin: 1rem 0;

  .AA & {
    font-family: $aa-heading-font;
    font-size: 2.3rem;
    line-height: 1.6;
    color: $aa-heading-color;
    margin: 0;
  }

  .DSD & {
    font-family: $dsd-heading-font;
    font-size: 2.9rem;
    line-height: 1.6;
    color: $dsd-heading-color;
    font-weight: normal;
    margin: 0;
  }
}

@mixin headingH2 {
  text-align: center;
  margin: 0;
  line-height: 1.4;

  :global(.CRO-1112-disney-orlando-messaging) & {
    text-align: left;

    @include fromDesktop {
      font-size: 2.6rem;
    }
  }

  .AA & {
    font-family: $aa-heading-font;
    font-size: 2.3rem;
    line-height: 1.4;
    color: $aa-heading-color;
  }

  .DSD & {
    font-family: $dsd-heading-font;
    font-size: 2.9rem;
    line-height: 1.4;
    color: $dsd-heading-color;
    font-weight: normal;
  }

  u {
    text-transform: uppercase;
    border-bottom: 2px solid #000;
    text-decoration: none;

    .DGS & {
      text-transform: none;
      border: none;
      text-decoration: none;
    }

    .AA & {
      border-bottom-color: $aa-heading-color;
    }
    .DSD & {
      border-bottom-color: $dsd-heading-color;
    }
  }
}

@mixin headingWrapper {
  text-align: center;
  margin: 0 0 2rem 0;
}

@mixin sectionHeading {
  @include headingH2;
  display: block;
  clear: both;
}

@mixin intro {
  font-size: $font-size-medium;
  text-align: center;
  margin: 0.5rem 0 0 0;
  display: inline-block;
  clear: both;

  :global(.CRO-1112-disney-orlando-messaging) & {
    display: block;
    text-align: left;
    justify-content: flex-start;
  }

  @include fromTablet {
    font-size: $font-size-big;

    .AA & {
      font-size: $font-size-medium;
    }
    .DSD & {
      font-size: $font-size-medium;
    }
  }

  sup {
    @include sup;
  }
}

@mixin save {
  display: block;
  color: $red;
  font-weight: 700;
  text-transform: uppercase;
  font-size: $font-size-smallest;
}

@mixin headingH3 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;

  .AA & {
    font-family: $aa-heading-font;
    font-size: 1.8rem;
    line-height: 1.4;
    color: $aa-heading-color;
  }

  .DSD & {
    font-family: $dsd-heading-font;
    font-size: 2rem;
    line-height: 1.4;
    color: $dsd-heading-color;
    font-weight: normal;
  }
}

@mixin sup {
  color: $price;
  font-weight: bold;

  .AA & {
    color: $aa-price;
  }
  .DSD & {
    color: $dsd-price;
  }
}

// loading spinner
@mixin loading() {
  position: relative;
  pointer-events: none;

  // overlay
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: $white;
    z-index: 3;
    opacity: 0.7;
  }

  // spinner
  &::after {
    background: none;
    content: "";
    box-sizing: border-box;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    width: 20px !important;
    height: 20px !important;
    margin-top: -10px !important;
    margin-left: -10px !important;
    border-radius: 50% !important;
    display: inline-block !important;
    border: 4px solid $green;
    border-top-color: transparent;
    .AA & {
      border-color: $aa-cta;
      border-top-color: transparent;
      display: inline-block;
    }
    .DSD & {
      border-color: $dsd-cta;
      border-top-color: transparent;
      display: inline-block;
    }
    z-index: 4;
    animation: spinner 0.6s linear infinite;
  }
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

@mixin btn {
  font-size: $font-size-medium;
  font-weight: 700;
  padding: 1.2rem 2rem;
  color: $white;
  background: $green;
  line-height: 1.2;
  text-align: center;
  text-transform: capitalize;
  border: solid 2px $green;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s;
  float: right;
  clear: right;
  position: relative;

  .AA & {
    background-color: $aa-cta;
    font-weight: bold;
    font-size: 1.3rem;
    text-transform: uppercase;
    line-height: 2;
    padding: 6px 14px;
    border-radius: 4px;
    border-color: $aa-cta;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    -webkit-appearance: none;
    width: 100%;
  }

  .DSD & {
    font-family: $dsd-heading-font;
    font-size: 1.5rem;
    text-transform: uppercase;
    line-height: 1.2;
    border-radius: 5px;
    padding: 15px 20px;
    border-color: $dsd-cta;
    font-weight: bold;
    background-color: $dsd-cta;
    width: 100%;
    background: linear-gradient(to bottom, $dsd-cta-hover 0, $dsd-cta 100%);
  }

  @include fromDesktop {
    width: $right-col-width-original;
    .DGS & {
      width: $right-col-width;
    }
    .AA & {
      width: $right-col-width-original;
    }
    .DSD & {
      width: $right-col-width-original;
    }
  }

  &:hover {
    background: darken($green, 10%);
    border-color: darken($green, 10%);

    .AA & {
      background-color: $aa-cta-hover;
      border-color: $aa-cta-hover;
    }

    .DSD & {
      background-color: $dsd-cta-hover;
      border-color: $dsd-cta-hover;
    }
  }

  &::after {
    border-style: solid;
    border-width: 3px 3px 0 0;
    content: "";
    display: inline-block;
    height: 4px;
    position: relative;
    top: 7px;
    left: 0;
    transform: rotate(45deg);
    vertical-align: top;
    width: 4px;
    margin-left: 0.5rem;
    .DGS & {
      transform: rotate(135deg);
      top: 5px;
    }

    .AA & {
      height: 8px;
      width: 8px;
      top: calc(50% - 5px);
      display: none;
    }

    .DSD & {
      height: 8px;
      width: 8px;
      top: calc(50% - 5px);
      display: none;
    }
  }

  &:disabled {
    background: $grey;
    border-color: $grey;
    cursor: not-allowed;
    color: $white;

    &:hover,
    &:focus {
      background: $grey;
      border-color: $grey;
      color: $white;

      .AA & {
        background: $grey;
        border-color: $grey;
        color: $white;
      }

      .DSD & {
        background: $grey;
        border-color: $grey;
        color: $white;
      }
    }
  }
}

@mixin basketBtn {
  @include btn;
  width: 100%;
  &::after {
    .DGS & {
      transform: rotate(45deg);
      top: 7px;
    }
  }
  @include fromDesktop {
    width: 100%;
    .DGS & {
      width: 100%;
    }
    .AA & {
      width: 100%;
    }
    .DSD & {
      width: 100%;
    }
  }
}

@mixin addtoorderBtn {
  @include btn;
  @include fromDesktop {
    width: 100%;
    .DGS & {
      width: 100%;
    }
    .AA & {
      width: 100%;
    }
    .DSD & {
      width: 100%;
    }
  }
}

@mixin unorderedList($listItemMargin: 0) {
  margin: 0 0 1rem 0;
  padding: 0;

  .AA & {
    margin: $aa-general-margin-small;
  }
  .DSD & {
    margin: $dsd-general-margin-small;
  }

  li {
    list-style-type: none;
    position: relative;
    padding: 0 0 0 2rem;
    margin: 0;
    margin-bottom: $listItemMargin;
    list-style-image: none;

    &::before {
      content: "";
      display: block;
      height: 8px;
      width: 8px;
      border-radius: 50%;
      position: absolute;
      top: 8px;
      left: 3px;
      background: $listdot;

      .AA & {
        background: $aa-listdot;
      }
      .DSD & {
        background: $dsd-listdot;
      }
    }
  }
}

@mixin info {
  display: inline-block;
  position: relative;
  cursor: pointer;
  font-weight: bold;
  vertical-align: middle;
  width: 20px;
  text-transform: none;
  height: 16px;

  &::after {
    content: "i";
    display: inline-block;
    color: $white;
    background: $blue;
    border-radius: 50%;
    height: 16px;
    width: 16px;
    line-height: 1.2;
    margin-left: 5px;
    font-size: 12px;
    text-align: center;
    top: 0;
    position: absolute;
    right: 0;

    .AA & {
      background-color: $aa-cta;
    }
    .DSD & {
      background-color: $dsd-cta;
    }
  }

  &:hover {
    &:after {
      .AA & {
        background-color: $aa-cta-hover;
      }
      .DSD & {
        background-color: $dsd-cta-hover;
      }
    }

    .infoText {
      display: block;
    }
  }
}

@mixin infoText($widthOfInfoBoxMobile: 200px, $widthOfInfoBoxDesktop: 200px) {
  display: none;
  position: absolute;
  border: 2px solid $blue;
  background: $grey-light;
  padding: 1rem;
  border-radius: 8px;
  z-index: 1;
  cursor: pointer;
  color: $black;
  font-weight: normal;
  font-size: $font-size-smaller;
  line-height: 1.5;
  width: $widthOfInfoBoxMobile;
  left: -80px;
  bottom: 20px;
  text-align: left;

  .AA & {
    border-color: $aa-cta;
    background-color: $aa-pale-bg;
    color: $aa-font-color;
  }
  .DSD & {
    border-color: $dsd-cta;
    background-color: $dsd-pale-bg;
    color: $dsd-font-color;
  }

  @include fromTablet {
    top: 16px;
    bottom: auto;
    left: 50%;
    transform: translateX(-50%);
    width: $widthOfInfoBoxDesktop;

    &::before {
      content: "";
      display: block;
      position: absolute;
      background: $grey-light;
      top: -6px;
      left: calc(50% - 5px);
      height: 12px;
      width: 12px;
      border: 2px solid $blue;
      border-color: $blue $blue $grey-light $grey-light;
      transform: rotate(-45deg) translateX(calc(50% - 5px));

      .AA & {
        background-color: $aa-pale-bg;
        border-color: $aa-cta $aa-cta $aa-pale-bg $aa-pale-bg;
      }
      .DSD & {
        background-color: $dsd-pale-bg;
        border-color: $dsd-cta $dsd-cta $dsd-pale-bg $dsd-pale-bg;
      }
    }
  }

  ul {
    @include unorderedList(0.5rem);
  }

  p {
    .AA & {
      margin: $aa-general-margin-small;
    }
    .DSD & {
      margin: $dsd-general-margin-small;
    }
  }

  a {
    color: $blue;
    .AA & {
      color: $aa-cta;
    }
    .DSD & {
      color: $dsd-cta;
    }
  }
}

@mixin toggleButton {
  background: 0;
  border: 0;
  display: inline-block;
  padding: 0 15px 0 0;
  position: relative;
  cursor: pointer;
  transition: none;
  border: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -webkit-border-radius: none;
  border-radius: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  color: $blue;
  margin: 0 1rem;

  .AA & {
    color: $aa-cta;
  }
  .DSD & {
    color: $dsd-cta;
  }

  &::after {
    // content: "";
    // display: block;
    // position: absolute;
    // height: 8px;
    // width: 8px;
    // border: 2px solid $blue;
    // border-width: 0 2px 2px 0;
    // transform: rotate(45deg);
    // right: 0;
    // top: calc(50% - 4px);

    border-style: solid;
    border-width: 3px 3px 0 0;
    content: "";
    display: inline-block;
    height: 4px;
    position: relative;
    top: 9px;
    left: 0;
    transform: rotate(135deg);
    vertical-align: top;
    width: 4px;
    margin-left: 0.7rem;

    .AA & {
      border-color: $aa-cta;
    }
    .DSD & {
      border-color: $dsd-cta;
    }
  }

  &[aria-expanded="true"] {
    margin: 0;
    &::after {
      transform: rotate(-45deg);
      top: 12px;
    }
  }

  &:hover {
    .AA & {
      color: $aa-cta-hover;
    }
    .DSD & {
      color: $dsd-cta-hover;
    }
    &:after {
      .AA & {
        border-color: $aa-cta-hover;
      }
      .DSD & {
        border-color: $dsd-cta-hover;
      }
    }
  }
}

@mixin calendarSelect {
  padding-top: 5px;
  padding-bottom: 5px;
  margin: 0 2px;
  // match colors of the prev/next btns
  border: 1px solid #e4e7e7;

  .AA & {
    background-color: white;
    font-size: $font-size-medium;
    border: solid 1px $aa-price-border;
    border-radius: 5px;
    color: $aa-font-color;
    padding: 10px;
    margin: 0 5px;
  }

  .DSD & {
    background-color: white;
    font-size: $font-size-medium;
    border: solid 1px $dsd-price-border;
    border-radius: 5px;
    color: $dsd-font-color;
    padding: 10px;
    margin: 0 5px;
  }
}
