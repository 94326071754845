@import "./styles/_base.scss";

// calendar overrides - all global styles
.dvpAppLayout {
  .CalendarDay {
    position: relative;
    overflow: hidden;

    &:active,
    &:hover {
      color: #484848;
      border: none;
      background-color: $white;
    }

    &.CalendarDay__selected {
      background-color: $white;
      &:active,
      &:hover {
        color: #484848;
        border: none;
        background-color: $white;
      }
    }

    &.CalendarDay__blocked_calendar {
      background-color: $white;
      &:active,
      &:hover {
        color: #484848;
        border: none;
        background-color: $white;
      }
    }
  }

  .CalendarMonthGrid__horizontal {
    display: flex;
    gap: 1.5rem;
    left: 0;
    padding-left: 0;
    @include fromMobile {
      padding-left: 1.5rem;
    }
  }

  .DayPicker {
    table,
    td {
      border: none;
    }
    td {
      padding: 0;
      border: 0;
      min-width: $calendar-month-width-original;
      @include fromTablet {
        min-width: $calendar-month-width-tablet-original;
      }
      @include fromDesktop {
        min-width: $calendar-month-width-desktop-original;
      }
    }
  }

  .DayPicker_transitionContainer {
    width: auto !important;
    height: auto !important;
    min-height: 390px;
    margin: auto;
    padding-top: 60px;

    @include fromDesktop {
      min-height: 410px;
    }
  }

  .DayPicker_weekHeaders {
    margin: 0;
    display: flex;
    gap: 1.5rem;
    position: absolute;
    top: 60px;
    padding: 0;

    @include fromMobile {
      padding: 0 1.5rem;
    }
    @include fromDesktop {
      top: 82px;
    }
  }

  .DayPicker_weekHeader {
    padding: 0 !important;
    margin: 0;
    position: static;
    @include fromDesktop {
      margin: 5px 0 0 0;
    }
  }

  .DayPicker_weekHeader_ul {
    margin: 0;
    padding: 0;
    width: 100%;
    font-size: $font-size-small;
    display: flex;
  }

  .DayPicker_weekHeader_li {
    display: block;
    padding: 0;
    margin: 0;
    text-align: center;
    min-width: $calendar-month-width-original;
    @include fromTablet {
      min-width: $calendar-month-width-tablet-original;
    }
    @include fromDesktop {
      min-width: $calendar-month-width-desktop-original;
    }
  }

  /**
  * Temp fix to class not being removed, do not hide the date picker.
  * Perhaps there is an issue loading this in a modal and how things
  * are calculated?
  */
  .DayPicker__hidden {
    visibility: visible;
  }

  .date-filter {
    display: inline-flex;
  }

  .DayPickerNavigation_leftButton__horizontalDefault {
    left: auto;
    top: 1rem;
    right: 49px;
    padding: 1rem;
    @include fromMobile {
      right: 6rem;
    }
    @include fromDesktop {
      left: 1rem;
      right: auto;
    }
  }

  .DayPickerNavigation_rightButton__horizontalDefault {
    right: 1rem;
    top: 1rem;
    padding: 1rem;
  }

  .CalendarMonth {
    padding: 0 !important;
  }

  .CalendarMonth_caption {
    padding-top: 0;
    padding-bottom: 2.5rem;
    font-size: $font-size-medium;

    @include fromDesktop {
      padding-top: 0;
      padding-bottom: 2.5rem;
    }
    select {
      display: block;
    }
    h3 {
      font-size: 2rem;
      font-weight: 400;
      margin: 0;
      padding: 0 0 0 5px;
      position: absolute;
      top: -50px;
      @include fromDesktop {
        position: static;
        top: 0;
      }
      strong {
        font-weight: normal;
      }
    }
  }

  .ticket-type-num-wrapper {
    overflow: hidden;
    padding-top: 1.5rem;
    text-align: center;
    .swiper-slide {
      max-width: 130px;
      min-width: 83px;
      width: auto;
      align-items: stretch;
      display: flex;
      flex-direction: column;
      height: auto;
    }
  }

  .swiper-slide {
    max-width: 232px;
    align-items: stretch;
    display: flex;
    flex-direction: column;
    height: auto;

    .ticket-type-num-wrapper & {
      max-width: 130px;
      min-width: 83px;
      width: auto;
    }
  }
}

// Austrilia specific fix due to long currency in the day prices
html[lang="en-au"] .dvpAppLayout .DayPicker {
  td {
    max-width: $calendar-month-width-tablet-original;
    @include fromTablet {
      max-width: $calendar-month-width-tablet-original;
    }
    @include fromDesktop {
      max-width: $calendar-month-width-desktop-original;
    }
  }
}
